<template>
    <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            
            <div class="overlay-panel rel">
                <div class="flex-container flex-col p24">
                    <div class="panel-header ">
                        <div class="mini-logo"></div>
                         <h2 class="panel-title nomargin bebas">{{title}}</h2>
                    </div>
                    <p>{{message}}</p>
                </div>  
                <div class="flex-container abs panel-footer w100">
                        <button class="w100 deny noborder nopadding bebas" @click="this.$emit('deny-confirm', message)">no</button>
                        <button class="w100 noborder nopadding bebas" @click="this.$emit('allow-confirm', message)">Elimina </button>
                    </div>
            </div> 
         </div>
      
    </teleport>
</template>
<script>
export default {
    props: {
    title: String,
    message:String
  }, 
  emits: ['deny-confirm', 'allow-confirm'],
}
</script>
<style lang="postcss">

.overlay-panel button.deny {
    color: white;
    background-color: black;
}
</style>